@import "variables";

#__next {
  width: 100%;
  height: 100%;
}
.app > div:first-child {
  width: 100%;
  height: 100%;
}
body,
div,
p,
span,
label,
b,
input,
button,
strong {
  font-family: Muli, "Source Sans Pro" !important;
}

::-webkit-scrollbar-thumb {
  background: #2196f3;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.search-box {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  img {
    height: 15px;
  }
  input {
    border: none;
    outline: none;
    margin-left: 10px;
    font-size: 14px;
    color: #000;
    flex: 1;
    width: 100%;
  }
}

.footer {
  display: flex;
  margin-top: 10px;
}

.grecaptcha-badge {
  z-index: 1;
}
.select__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.validate {
  color: red;
  font-weight: bold;
  font-size: 15px;
}
.error {
  margin-bottom: -24px;
  color: #f5222d;
  font-size: 14px !important;
  font-weight: 500;
}
@media (max-width: 992px) {
  .nav-function-fixed:not(.nav-function-top):not(.nav-function-hidden):not(.nav-function-minify) .page-content-wrapper {
    padding-left: 0 !important;
  }
  .header-function-fixed:not(.nav-function-top) .page-header {
    margin-left: 0 !important;
  }
}
