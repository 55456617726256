@import "variables";

/**
CAROUSEL
 */
.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 24px;
  height: 24px;
  background-color: $secondary-color;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  border-radius: 12px;
  cursor: pointer;
}

.carousel-control-prev {
  left: -41px;
  width: auto;
  opacity: 1;
}

.carousel-control-next {
  right: -41px;
  width: auto;
  opacity: 1;
}
/**
END CAROUSEL
 */

/**
BUTTON
 */
.btn-primary, .btn-primary:hover {
  background-color: $secondary-color;
  border-color: $secondary-color;
  border-radius: 16px;
}

.btn-success, .btn-success:hover {
  background-color: $primary-color;
  border-color: $primary-color;
  border-radius: 16px;
}
.navbar-toggler{
  border: none;
  outline: none!important;
}
/**
END BUTTON
 */

/**
MISC
 */
.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}
body{
  width: 100%;
  height: 100%;
}
.carousel-indicators{
  bottom: 40px;
}
// .active {
//   color: $primary-color;
//   font-weight: 500;
//   border-bottom: 4px solid;
// }