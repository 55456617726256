$color-background: #0d2745;
.container__input {
    width: 374px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .header {
        color: #ffffff;
        height: 60px;
        background-color: $color-background;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        .title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.01em;
        }
    }
}
